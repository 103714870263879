import React, { Suspense, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import AppRoutes from "../routes/routes";
import { BrowserRouter } from "react-router-dom";
import SuspenseFallback from "./SuspenseFallBack/SuspenseFallback";
import ErrorPage from "../pages/ErrorPage";
import { compose } from "redux";
import { Provider } from "react-redux";
import { Middlewares } from "../middleware";

import store from "../store/store";
import "../App.css";

// Added by Fernando B silva
import "bootstrap/dist/css/bootstrap.min.css";

//Redux DevTools for chrome extension do not delete
declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

interface AppProps {}
const App: React.FC<AppProps> = ({}) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <Provider store={store}>
        <BrowserRouter>
          <Suspense fallback={<SuspenseFallback />}>
            <Middlewares>
              <div id="header"></div>
              <div id="content">
                <AppRoutes />
              </div>
            </Middlewares>
          </Suspense>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  );
};
export default App;
