import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import streamSaver from "streamsaver";
import App from "./components/App";
import { Provider } from "react-redux";
import store from "./store/store";
import { TokenProvider } from "./utilities/TokenProvider";
import ReactDOM from "react-dom";
streamSaver.mitm =
  process.env.REACT_APP_FRONTEND_BASE_URL + "/streamsaver/mitm.html";

ReactDOM.render(<App />, document.getElementById("root"));

// const root = createRoot(document.getElementById('root'));
// root.render(
//   <Provider store={store}>
//     <TokenProvider>
//       <App />
//     </TokenProvider>
//   </Provider>
// );

// const root = createRoot(document.getElementById('root'));
// root.render(<App />);
